import React, { FormEvent } from 'react';

type Props = {
  id: string,
  label: string,
  onInput: (value: string) => void
  value: string
};

type State = {
  isInputFocused: boolean
}


class Input extends React.Component<Props, State> {
  state: State = {
    isInputFocused: false,
  };

  handleBlur = (): void => {
    this.setState({ isInputFocused: false });
  };

  handleFocus = (): void => {
    this.setState({ isInputFocused: true });
  };

  handleInput = (event: FormEvent): void => {
    this.props.onInput((event.target as HTMLInputElement).value);
  };

  render() {
    return (
      <div className={'input-field inline'}>
        <input
          id={this.props.id}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleInput}
          value={this.props.value}
        />
        <label
          className={this.state.isInputFocused || this.props.value ? 'active' : ''}
          htmlFor={this.props.id}
        >
          { this.props.label }
        </label>
      </div>
    );
  }
}

export default Input;