import req from '../lib/req';

export type Label = {
  name: string,
};

export type Task = {
  id: string,
  title: string,
  labels: Label[],
  url: string,
  urlToMr: string|null,
  projectId: string|null,
}


export function getTasks(): Promise<Task[]> {
  return req.get('/tasks');
}

export function getRunningTask(): Promise<Task> {
  return req.get('/tasks/running');
}

export function setRunningTask(task: Task) {
  return req.put('/tasks/running', task);
}

export function deleteRunningTask(task: Task) {
  return req.delete('/tasks/running', {
    data: task
  });
}