import React from 'react';
import { Label, Task } from '../api/modules/tasks';

type Props = {
  isRunning: boolean
  onTimerToggle: (task: Task) => void,
  task: Task
};

const createTimerButton = ({ isRunning, onTimerToggle, task}: Props): React.ReactNode => {
  return (
    <button
      className={`btn-flat ${isRunning ? 'red-text' : ''}`}
      onClick={() => onTimerToggle(task)}
    >
      { `${isRunning ? 'Stop' : 'Start'} timer` }
    </button>
  );
};

const createMrButton = ({ task }: Props): React.ReactNode => {
  if (!task.urlToMr) {
    return;
  }

  return (
    <a target="_blank" href={task.urlToMr}>
      Go to MR
    </a>
  );
}

const Card: React.FC<Props> = (props: Props) => (
  <div className={'col s12 m6 xl4'}>
    <div className={'card'}>
      <div className={'card-content'}>
        <span className={'card-title'} title={ props.task.title }>
          { props.task.id
              ? <a target="_blank" href={ props.task.url }>#{ props.task.id }</a>
              : '' }
          { ' ' + props.task.title }
        </span>

        <div className={'card-labels'}>
          {props.task.labels.map((label: Label) => (
            <div
              key={label.name}
              className={'chip'}
            >
              { label.name }
            </div>
          ))}
        </div>
      </div>

      <div className={'card-action right-align'}>
        { createMrButton(props) }
        { createTimerButton(props) }
      </div>
    </div>
  </div>
);

export default Card;