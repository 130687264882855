import React from 'react';
import Input from './form/Input';

type Props = {
  onSubmit: (tokens: Tokens) => void,
};

// @todo bad location
// @todo needs to be kept in sync with controller.ts.
export type Tokens = {
  gitlab: string,
  toggl: string,
  clockify: string,
}

type State = {
  tokens: Tokens
}


class Prompt extends React.Component<Props, State> {
  state: State = {
    tokens: {
      gitlab: '',
      toggl: '',
      clockify: '',
    }
  };

  handleSubmit = (): void => {
    this.props.onSubmit(this.state.tokens);
  };

  render() {
    return (
      <form
        className={'prompt'}
        onSubmit={this.handleSubmit}
      >
        <div>
          <span>GitLab</span>

          <Input
            id={'gitlab-token'}
            label={'GitLab\'s token'}
            onInput={token => this.setState({ tokens: { ...this.state.tokens, gitlab: token } })}
            value={this.state.tokens.gitlab}
          />

          <a
            href={'https://gitlab.com/profile/personal_access_tokens'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            Where can I find the token?
          </a>
        </div>

        <div>
          <span>Toggl</span>

          <Input
            id={'toggl-token'}
            label={'Toggl\'s API key'}
            onInput={token => this.setState({ tokens: { ...this.state.tokens, toggl: token } })}
            value={this.state.tokens.toggl}
          />

          <a
            href={'https://toggl.com/app/profile'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            Where can I find the token?
          </a>
        </div>

        <div>
          <span>Clockify</span>

          <Input
            id={'clockify-token'}
            label={'Clockify\'s API key'}
            onInput={token => this.setState({ tokens: { ...this.state.tokens, clockify: token } })}
            value={this.state.tokens.clockify}
          />

          <a
            href={'https://clockify.me/user/settings'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            Where can I find the token?
          </a>
        </div>

        <div className={'input-field inline'}>
          <button
            className={'btn'}
            disabled={!(this.state.tokens.gitlab && (this.state.tokens.toggl || this.state.tokens.clockify))}
            type={'submit'}
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default Prompt;