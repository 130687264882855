import axios from 'axios';
import { Tokens } from '../../components/Prompt';

const tokens: Tokens = {
  gitlab: localStorage.getItem('gitlab') || '',
  toggl: localStorage.getItem('toggl') || '',
  clockify: localStorage.getItem('clockify') || '',
};

const instance = axios.create({
  baseURL: '/api/v1/',
  headers: {
    ...Object.entries(tokens).reduce((headers: { [key: string]: string}, [name, token]: [string, string]) => {
      if (!token) {
        return headers;
      }

      return {
        ...headers,
        [`X-Token-${name}`]: token,
      }
    }, {}),
  }
});

instance.interceptors.response.use(res => res.data);

export default instance;
