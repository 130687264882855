import React from 'react';
import Prompt, { Tokens } from './components/Prompt';
import Tasks from './components/Tasks';
import './main.scss';
import Navbar from './components/Navbar';
import { Task } from './api/modules/tasks';

type State = {
  tokens: Tokens,
}


class App extends React.Component {
  state: State = {
    tokens: {
      gitlab: '',
      toggl: '',
      clockify: '',
    },
  };

  componentDidMount(): void {
    this.setState({
      tokens: {
        gitlab: localStorage.getItem('gitlab') || '',
        toggl: localStorage.getItem('toggl') || '',
        clockify: localStorage.getItem('clockify') || '',
      },
    });
  }

  handleTokensSubmit = (tokens: Tokens) => {
    Object.entries(tokens).forEach(([name, token]: [string, string]) => {
      localStorage.setItem(name, token);
    });
    this.setState({ tokens });
  };

  updateTitle = (task: Task|null): void => {
    if (!task) {
      document.title = 'Dashboard';
    } else if (!task.id) {
      document.title = task.title;
    } else {
      document.title = `#${task.id} ${task.title}`;
    }
  };

  render() {
    const tokensSet = this.state.tokens.gitlab && (this.state.tokens.toggl || this.state.tokens.clockify);

    return (
      <React.Fragment>
        <Navbar
          title={'Dashboard'}
          links={[
            { url: 'https://theater.stg.i-smith.ee', name: 'Staging' },
            { url: 'https://teater.i-smith.ee', name: 'Production' },
            { url: 'https://clockify.me/tracker', name: 'Clockify' },
            { url: 'https://gitlab.com', name: 'Gitlab' },
            { url: 'https://grafana.i-smith.ee', name: 'Grafana' },
            { url: 'https://karma.i-smith.ee', name: 'Karma' },
            { url: 'https://portainer.dev.i-smith.ee', name: 'Portainer' },
            { url: 'https://satis.i-smith.ee', name: 'Satis' },
          ]}
        />
        <div className={'App container'}>
          {tokensSet && (
            <Tasks
              onRunningTaskChanged={this.updateTitle}
            />
          )}
          {!tokensSet && (
            <Prompt
              onSubmit={this.handleTokensSubmit}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default App;
