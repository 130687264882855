import React from 'react';
import api from '../api';
import { Task } from '../api/modules/tasks';
import Card from './Card';

type State = {
  tasks: Task[],
  runningTask: Task|null
}

type Props = {
  onRunningTaskChanged: (task: Task|null) => void,
}


class Tasks extends React.Component<Props, State> {
  state: State = {
    tasks: [],
    runningTask: null
  };

  componentDidMount(): void {
    this.getTasks();
    this.getRunningTask();
  }

  getTasks = (): void => {
    api.tasks.getTasks()
      .then((tasks: Task[]) => {
        this.setState({ tasks });
      });
  };

  getRunningTask = (): void => {
    api.tasks.getRunningTask()
      .then((runningTask: Task) => {
        this.setState( { runningTask });
        this.props.onRunningTaskChanged(runningTask);
      })
      .catch(() => {
        this.setState( { runningTask: null });
        this.props.onRunningTaskChanged(null);
      })
  };

  setRunningTask = (runningTask: Task): void => {
    api.tasks.setRunningTask(runningTask)
      .then(() => {
        this.setState({ runningTask });
        this.props.onRunningTaskChanged(runningTask);
      });
  };

  deleteRunningTask = (runningTask: Task): void => {
    api.tasks.deleteRunningTask(runningTask)
      .then(() => {
        this.setState({ runningTask: null });
        this.props.onRunningTaskChanged(null);
      });
  };

  render() {
    return (
      <div className={'row'}>
        {this.state.tasks.map(task => {
          const isRunningAnIssue = !!(this.state.runningTask && task.id && task.id === this.state.runningTask.id);
          const isRunningAPreset = !!(this.state.runningTask && !task.id && !this.state.runningTask.id && task.title === this.state.runningTask.title);
          const isRunning = isRunningAnIssue || isRunningAPreset;

          return (
            <Card
              key={task.id}
              isRunning={isRunning}
              onTimerToggle={isRunning ? this.deleteRunningTask : this.setRunningTask}
              task={task}
            />
          );
        })}
      </div>
    );
  }
}

export default Tasks;