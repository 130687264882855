import React from 'react';
import M from 'materialize-css';

type Props = {
  title: string,
  links: {
    url: string,
    name: string,
  }[],
};

type State = {};

class Navbar extends React.Component<Props, State> {

  componentDidMount(): void {
    const elements = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elements);
  }

  render() {
    const linkItems = this.props.links.map((link) => {
      return (
        <li key={link.url.toString()}>
          <a href={link.url} target="_blank">{link.name}</a>
        </li>
      );
    });

    return (
      <React.Fragment>
        <nav className={'navbar'}>
          <div className="nav-wrapper container">
            <a href="#!" className="brand-logo">{this.props.title}</a>
            <ul className="right hide-on-med-and-down">
              {linkItems}
            </ul>
            <a href="#" data-target="mobile-demo" className="right sidenav-trigger"><i className="material-icons">menu</i></a>
          </div>
        </nav>
        <ul className="sidenav" id="mobile-demo">
          {linkItems}
        </ul>
      </React.Fragment>
    );
  }
}

export default Navbar;
